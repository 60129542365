import React from "react";
import styled from "styled-components";
export const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = name + "=" + encodeURIComponent(value) + "; expires=" + expires;
};
const ConstantContactPopup = ({
  showPopup,
  setShowPopup
}) => {
  const closePopup = () => {
    setShowPopup(false);
    setCookie("kawasaki-evo-popup", "true", 30);
  };
  return /* @__PURE__ */ React.createElement(Popup, {
    id: "ctct-popup",
    className: `ctct-popup ${showPopup ? "ctct-popup-show" : ""}`
  }, /* @__PURE__ */ React.createElement(PopupContent, null, /* @__PURE__ */ React.createElement(PopupClose, {
    onClick: closePopup
  }, /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 348.333 348.334"
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "#565656",
    d: "M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "ctct-inline-form",
    "data-form-id": "26a813cd-4ebe-436d-8b29-143e549b117a"
  })));
};
export default ConstantContactPopup;
const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 18px rgba(0,0,0,.2);
  z-index: -1;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .5s .5s;

  &.ctct-popup-show {
    z-index: 999;
    opacity: 1;
  }
`;
const PopupClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  svg {
    width: 12px;
    height: 12px;
  }
`;
const PopupContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background: #000;
  padding: 0;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  //Overwrite some styles from the constant contact form
`;
