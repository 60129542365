var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState, useEffect } from "react";
import MainNavigation from "../components/MainNavigation";
import ConstantContactPopup from "../components/ConstantContactPopup";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
export const getCookie = (name) => {
  var _a;
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return (_a = parts.pop()) == null ? void 0 : _a.split(";").shift();
};
const GlobalStyle = createGlobalStyle`
  /* overrides for onetrust module */
  #onetrust-consent-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler {
    background-color: #E60012 !important;
    border-color: #E60012 !important;
  }
  #onetrust-banner-sdk #onetrust-policy-title {
    color: #E60012 !important;
  }
  #onetrust-consent-sdk #onetrust-banner-sdk a[href], #onetrust-consent-sdk #onetrust-banner-sdk a[href] font, #onetrust-consent-sdk #onetrust-banner-sdk .ot-link-btn {
    color: #E60012 !important;
  }
  div.ctct-form-embed .ctct-form-defaults .ctct-form-header {
    color: #E60012 !important;
  }
  div.ctct-form-embed form.ctct-form-custom .ctct-form-required:before {
    color: #E60012 !important;
  }
  div.ctct-form-embed .ctct-form-custom .ctct-form-button {
    background-color: #E60012 !important;
    border: 1px solid #E60012 !important;
  }
`;
export default (props) => {
  useEffect(() => {
    for (let key in localStorage) {
      if (key.startsWith("ctct")) {
        localStorage.removeItem(key);
      }
    }
    if (props.path != "/") {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      script.setAttribute("src", "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js");
      script.setAttribute("id", "signupScript");
      script.setAttribute("defer", "true");
      head.appendChild(script);
      const popupCookie = getCookie("kawasaki-evo-popup");
      if (popupCookie === "" || popupCookie === void 0) {
        setShowPopup(true);
      }
    }
  }, []);
  const [showPopup, setShowPopup] = useState(false);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, props.title), /* @__PURE__ */ React.createElement("meta", {
    name: "description",
    content: props.metaDescription
  }), /* @__PURE__ */ React.createElement("script", {
    src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    type: "text/javascript",
    charSet: "UTF-8",
    "data-domain-script": "0c3100cc-5291-4f96-8717-1e9ec7bcfa35"
  }), /* @__PURE__ */ React.createElement("script", {
    src: "https://cdn.cookielaw.org/opt-out/otCCPAiab.js",
    type: "text/javascript",
    charSet: "UTF-8",
    "ccpa-opt-out-ids": "C0002,C0004",
    "ccpa-opt-out-geo": "ca",
    "ccpa-opt-out-lspa": "true"
  }), /* @__PURE__ */ React.createElement("script", null, ' var _ctct_m = "c32394b47ca25f8061c509d849195cc6"; ')), /* @__PURE__ */ React.createElement(GlobalStyle, null), props.path != "/" && /* @__PURE__ */ React.createElement(ConstantContactPopup, {
    showPopup,
    setShowPopup
  }), /* @__PURE__ */ React.createElement(MainNavigation, __spreadValues({}, props)), props.children);
};
